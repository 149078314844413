
import {
  formatDate,
  formatPriceRP,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import { ClearanceController } from "@/app/ui/controllers/ClearanceController";
import {
  ClearanceDetailData,
  ClearanceDetailStt,
} from "@/domain/entities/Clearance";
import { Options, Vue } from "vue-class-component";
import PrintManifestClearance from "../modules/print.vue";

@Options({
  components: {
    PrintManifestClearance
  }
})
export default class Detail extends Vue {
  refs: any = "";
  mounted() {
    this.getDetailClearance();
    this.refs = this.$refs;
  }

  get getTitle() {
    return this.$route.params.id;
  }

  goBack() {
    this.$router.push("/clearance");
  }

  formatPrice(val: any) {
    return formatPriceRP(val, "MYR");
  }
  isLoading = false;
  errorMessage = "";
  isError = false;
  detailData = new ClearanceDetailData();

  async getDetailClearance() {
    try {
      this.isLoading = true;
      this.isError = false;
      const res = await ClearanceController.getClearanceDetail(
        this.$route.params.id
      );
      this.onMappingDetailClearance(res);
    } catch (error) {
      this.isError = true;
      this.errorMessage = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
      this.isError = false;
    }
  }
  onMappingDetailClearance(data: any) {
    this.detailData = data;
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.clearanceCreatedAt);
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-10 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt, index: number) => {
        return `<div class="text-black-lp-300">${index + 1}</div>`;
      },
    },
    {
      name: `No. Bag`,
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300">${item.clearanceBagNo}</div>`;
      },
    },
    {
      name: `No. STT`,
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300">${item.clearanceSttNo}</div>`;
      },
    },
    {
      name: `Berat`,
      styleHead: "w-28 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300">${item.clearanceSttWeight} Kg</div>`;
      },
    },
    {
      name: `Harga Barang`,
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300">${this.formatPrice(
          item.clearanceSttGoodsPrice
        )}</div>`;
      },
    },
    {
      name: `Komoditas`,
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300">${item.clearanceSttCommodityName}</div>`;
      },
    },
    {
      name: "Origin",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.clearanceOriginCity}
                  </div>
                </div>`;
      },
    },
    {
      name: "Dest.",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleCustom: "align-top",
      render: (item: ClearanceDetailStt) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.clearanceDestinationCity}
                  </div>
                </div>`;
      },
    },
  ];

  printManifest() {
    this.refs.printManifest.printManifest(this.detailData);
  }
}
