import { ClearanceListRequest } from "@/domain/entities/Clearance";

export class ClearanceEndpoints {
  getClearanceList(params: ClearanceListRequest) {
    return `/hydra/v1/clearance?${params.toQueryString()}`;
  }
  getClearanceDetail(id: any) {
    return `/hydra/v1/clearance/${id}`;
  }
  updateBulkUpdateClearance() {
    return "/hydra/v1/clearance";
  }
}
