import { ClearanceMapper } from "@/data/persistences/mappers/ClearanceMapper";
import ApiService from "../../services/ApiService";
import { ClearanceEndpoints } from "../../endpoints/ClearanceEndpoint";
import { ClearanceRepositoryInterface } from "@/data/persistences/repositories/contracts/ClearanceRepositoryInterface";
import {
  ClearanceDetailData,
  ClearanceEntities,
  ClearanceListRequest,
} from "@/domain/entities/Clearance";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { UpdateClearanceRequestInterface } from "@/data/payload/contracts/ClearanceRequest";

export class ClearanceApiRepository implements ClearanceRepositoryInterface {
  private service: ApiService;
  private mapper: ClearanceMapper;
  private endpoints: ClearanceEndpoints;

  constructor(
    service: ApiService,
    mapper: ClearanceMapper,
    endpoints: ClearanceEndpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async updateBulkUpdateClearance(payload: UpdateClearanceRequestInterface): Promise<ResponsePayloadV2> {
    const response = await this.service.invokePostWithFormData("post", 
    this.endpoints.updateBulkUpdateClearance(),
    {},
    payload
    )
    return this.mapper.convertUpdateBulkClearanceDataFromApi(response);
  }

  public async getClearanceList(
    params: ClearanceListRequest
  ): Promise<ClearanceEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getClearanceList(params),
      {}
    );
    return this.mapper.convertClearanceListDataFromApi(resp);
  }

  public async getClearanceDetail(
    id: any
  ): Promise<ClearanceDetailData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getClearanceDetail(id),
      {}
    );
    return this.mapper.convertClearanceDetailDataFromApi(resp);
  }
}
