import { injectable } from "tsyringe";
import { ClearanceApiRepository } from "@/app/infrastructures/repositories/api/ClearanceApiRepository";
import { ClearanceDetailData, ClearanceEntities, ClearanceListRequest } from "@/domain/entities/Clearance";
import { UpdateClearanceRequestInterface } from "@/data/payload/contracts/ClearanceRequest";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

@injectable()
export class ClearancePresenter {
  private repository: ClearanceApiRepository;

  constructor(repository: ClearanceApiRepository) {
    this.repository = repository;
  }

  public getListClearance(params: ClearanceListRequest): Promise<ClearanceEntities> {
    return this.repository.getClearanceList(params);
  }
  public getDetailClearance(id: any): Promise<ClearanceDetailData> {
    return this.repository.getClearanceDetail(id);
  }
  public updateBulkUpdateClearance(params: UpdateClearanceRequestInterface): Promise<ResponsePayloadV2> {
    return this.repository.updateBulkUpdateClearance(params);
  }
}
