import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule
  } from "vuex-module-decorators";
  import store from "@/store";
  import {
    ClearanceEntities,
    ClearanceListRequest
  } from "@/domain/entities/Clearance";
  import { Pagination } from "@/domain/entities/Pagination";
  import { container } from "tsyringe";
  import { ClearancePresenter } from "../presenters/ClearancePresenter";
  import { ClearanceComponent } from "@/app/infrastructures/dependencies/modules/ClearanceComponent";
import { UpdateClearanceRequestInterface } from "@/data/payload/contracts/ClearanceRequest";
import { BulkUpdateClearanceRequest } from "@/data/payload/api/BulkUpdateClearanceRequest";
  ClearanceComponent.init();
  export interface ClearanceState {
    isLoading: boolean;
    isError: boolean;
    errorCause: string;
    ClearanceList: ClearanceEntities;
  }
  
  @Module({ namespaced: true, dynamic: true, store, name: "Clearance" })
  class ClearanceStore extends VuexModule implements ClearanceState {
    public isLoading = false;
    public isLoadingDetail = false;
    public isError = false;
    public errorCause = "";
    public filter: any = {
      startDate: "",
      endDate: "",
      search: ""
    };
    public periodeStart: any = "";
    public periodeEnd: any = "";
    public ClearanceList = new ClearanceEntities(new Pagination(1, 20), []);
    public showFormUpdate = false;
  
    @Action
    public getClearanceList(params: ClearanceListRequest) {
      const presenter = container.resolve(ClearancePresenter);
      return presenter.getListClearance(params)
    }

    @Action
    public getClearanceDetail(id: any) {
      const presenter = container.resolve(ClearancePresenter);
      return presenter.getDetailClearance(id);
    }
    @Action
    public updateBulkUpdateClearance(params: BulkUpdateClearanceRequest) {
      const presenter = container.resolve(ClearancePresenter);
      return presenter.updateBulkUpdateClearance(params);
    }
  
    @Mutation
    private setClearanceList(data: ClearanceEntities) {
      this.ClearanceList = data;
    }
  
    @Mutation
    public setIsLoading(value: boolean) {
      this.isLoading = value;
    }

    @Mutation
    public setShowFormUpdate(value: boolean) {
      this.showFormUpdate = value;
    }
  
    @Mutation
    public setIsLoadingDetail(value: boolean) {
      this.isLoadingDetail = value;
    }
  
    @Mutation
    public setSearch(value: string) {
      this.filter.search = value;
      this.ClearanceList.pagination.page = 1;
    }
  
    @Mutation
    public setPeriodeStart(value: any) {
      this.periodeStart = value;
    }
  
    @Mutation
    public setPeriodeEnd(value: any) {
      this.periodeEnd = value;
    }
  
    @Mutation
    public setError(value: boolean) {
      this.isError = value;
    }

    @Mutation
    public setErrorCause(value: string) {
      this.errorCause = value;
    }
  }
  
  export const ClearanceController = getModule(ClearanceStore);
  