
import ModalFormBulkUpdateClearance from "./components/bulk-update-clearance.vue";
import { formatDate, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { debounce } from "lodash";
import { Vue, Options } from "vue-class-component";
import { ClearanceController } from "@/app/ui/controllers/ClearanceController";
import {
  ClearanceData,
  ClearanceEntities,
  ClearanceListRequest,
} from "@/domain/entities/Clearance";
import { FlagsPermissionOutgoingShipmentClearance } from "@/feature-flags/flags-outgoing-shipment-clearance";
import { Pagination } from "@/domain/entities/Pagination";

@Options({
  components: {
    ModalFormBulkUpdateClearance,
  },
})
export default class ClearanceList extends Vue {
  mounted() {
    this.setReset()
    this.fetchClearanceList()
  }
  get Title() {
    return this.$route.name;
  }
  get ableToDetail() {
    return FlagsPermissionOutgoingShipmentClearance.permission_clearance_detail_view.isEnabled();
  }
  goToDetail(item: ClearanceData) {
    // function to detail page
    this.$router.push(`/clearance/${item.clearanceNo}`);
  }
  goToUpdate() {
    ClearanceController.setShowFormUpdate(true);
  }
  // filter date
  get periodeStart() {
    return ClearanceController.periodeStart;
  }

  get periodeEnd() {
    return ClearanceController.periodeEnd;
  }

  setReset() {
    ClearanceController.setSearch("");
    ClearanceController.setPeriodeStart(new Date(new Date(new Date().setDate(new Date().getDate() - 6))));
    ClearanceController.setPeriodeEnd(new Date(new Date(new Date().setDate(new Date().getDate()))));
  }

  // time-range-picker
  minDate: any = null;
  maxDate: any = null;
  tempPeriodeStart: any = null;
  tempPeriodeEnd: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempPeriodeStart = this.periodeStart;
      this.tempPeriodeEnd = this.periodeEnd;
      ClearanceController.setPeriodeStart(null);
      ClearanceController.setPeriodeEnd(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 13)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 13)
      );
      this.isStartDate = false;
    } else {
      this.periodeEnd && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      ClearanceController.setPeriodeStart(this.tempPeriodeStart);
      ClearanceController.setPeriodeEnd(this.tempPeriodeEnd);
      this.tempPeriodeStart = null;
      this.tempPeriodeEnd = null;
      this.isStartDate = true;
    }
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    ClearanceController.setPeriodeStart(value[0]);
    ClearanceController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchClearanceList();
  }

  // filter search
  get searchValue() {
    return ClearanceController.filter.search;
  }

  onSearch = debounce((val: string) => {
    ClearanceController.setSearch(val);
    this.fetchClearanceList();
  }, 250);

  clearSearch() {
    ClearanceController.setSearch("");
    this.fetchClearanceList();
  }

  
  // loading
  isLoading = false;
  // error cause
  errorCause = "";
  isError = false;

  get filter() {
    return ClearanceController.filter;
  }

  async fetchClearanceList() {
    // hit endpoint get list clearance
    try {
      this.isLoading = true;
      this.isError = false;
      this.errorCause = "";
      const res = await ClearanceController.getClearanceList(
        new ClearanceListRequest({
          page: this.pagination.page,
          limit: this.pagination.limit,
          search: encodeURIComponent(this.searchValue),
          startDate: this.periodeStart?.toISOString().slice(0, 10),
          endDate: this.periodeEnd?.toISOString().slice(0, 10),
        })
      );
      this.onMappingClearanceList(res);
    } catch (error) {
      this.isError = true;
      this.errorCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
      this.isError = false;
    }
  }

  onMappingClearanceList(data: ClearanceEntities) {
    this.clearanceList = data;
  }

  clearanceList = new ClearanceEntities(new Pagination(1, 20), []);
  get pagination() {
    return this.clearanceList.pagination;
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-1/4 text-left whitespace-no-wrap",
      render: (item: any, index: any) => {
        return `<div class="overflow-ellipsis text-left">${
          index + 1 + this.pagination.limit * (this.pagination.page - 1)
        }</div>`;
      },
    },
    {
      name: "No. Clearance",
      styleHead: "w-2/4 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.clearanceNo}</div>`;
      },
    },
    {
      name: "Total Berat",
      styleHead: "w-2/4 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.clearanceTotalWeight} Kg</div>`;
      },
    },
    {
      name: "Total Koli",
      styleHead: "w-2/4 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.clearanceTotalPieces}</div>`;
      },
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-2/4 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.clearanceCreatedAt
        )}</div>
            <div class="capitalize text-gray-lp-500 text-12px">${
              item.clearanceCreatedName
            }</div>`;
      },
    },
  ];

  get showFormUpdate() {
    return ClearanceController.showFormUpdate;
  }
}
