import { ConvertObjectCamelToSnakeCase, toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { UpdateClearanceRequestInterface } from "../contracts/ClearanceRequest";

export class BulkUpdateClearanceRequest implements UpdateClearanceRequestInterface {
    attachment = "";

    constructor(fields?: Partial<BulkUpdateClearanceRequest>) {
        Object.assign(this, fields);
    }

    public toFormData(): FormData {
        return toFormDataUtils(ConvertObjectCamelToSnakeCase(this));
    }

}