import { ClearancePresenter } from "@/app/ui/presenters/ClearancePresenter";
import { ClearanceMapper } from "@/data/persistences/mappers/ClearanceMapper";
import { container } from "tsyringe";
import { ClearanceEndpoints } from "../../endpoints/ClearanceEndpoint";
import { ClearanceApiRepository } from "../../repositories/api/ClearanceApiRepository";
import ApiService from "../../services/ApiService";

export class ClearanceComponent {
    public static init() {

        container.register<ClearanceApiRepository>(ClearanceApiRepository, {
            useFactory: d => {
                return new ClearanceApiRepository(
                    d.resolve(ApiService),
                    d.resolve(ClearanceMapper),
                    d.resolve(ClearanceEndpoints)
                );
            }
        });
        container.register<ClearanceMapper>(ClearanceMapper, {
            useClass: ClearanceMapper
        });
        container.register<ClearancePresenter>(ClearancePresenter, {
            useFactory: d => {
                return new ClearancePresenter(d.resolve(ClearanceApiRepository));
            }
        });
    }
}
