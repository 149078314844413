import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ClearanceData,
  ClearanceDetailStt,
  ClearanceDetailData,
  ClearanceEntities,
} from "@/domain/entities/Clearance";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

export class ClearanceMapper {
  public convertClearanceListDataFromApi(
    res: AxiosResponse<any>
  ): ClearanceEntities {
    const { data } = res;

    const clearance: ClearanceData[] = [];
    if (data.data.length === 0) {
      return new ClearanceEntities(
        new Pagination(
          data.meta.page,
          data.meta.limit,
          data.meta.total_records
        ),
        []
      );
    } else {
      data.data.map((item: any) => {
        clearance.push(
          new ClearanceData({
            clearanceNo: item.clearance_no,
            clearanceTotalWeight: item.clearance_total_weight,
            clearanceTotalPieces: item.clearance_total_pieces,
            clearanceCreatedAt: item.clearance_created_at,
            clearanceCreatedBy: item.clearance_created_by,
            clearanceCreatedName: item.clearance_created_name,
          })
        );
      });
    }
    return new ClearanceEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      clearance
    );
  }

  public convertClearanceDetailDataFromApi(
    res: AxiosResponse<any>
  ): ClearanceDetailData {
    const { data } = res;
    return new ClearanceDetailData({
      clearanceNo: data.data.clearance_no,
      clearanceTotalWeight: data.data.clearance_total_weight,
      clearanceTotalPieces: data.data.clearance_total_pieces,
      clearanceCreatedAt: data.data.clearance_created_at,
      clearanceCreatedBy: data.data.clearance_created_by,
      clearanceCreatedName: data.data.clearance_created_name,
      clearanceTotalGoodsPrice: data.data.clearance_total_goods_price,
      clearanceDetailStt:
        data.data.clearance_detail_stt.length === 0
          ? []
          : data.data.clearance_detail_stt.map((item: any) => {
            return new ClearanceDetailStt({
              clearanceBagNo: item.clearance_bag_no || "-",
              clearanceSttNo: item.clearance_stt_no || "-",
              clearanceDestinationCity: item.clearance_destination_city || "-",
              clearanceOriginCity: item.clearance_origin_city || "-",
              clearanceSttWeight: item.clearance_stt_weight || 0,
              clearanceSttCommodityCode: item.clearance_stt_commodity_code || "-",
              clearanceSttGoodsPrice: item.clearance_stt_goods_price || 0,
              clearanceSttCommodityName: item.clearance_stt_commodity_name || "-",
              clearanceSttCommodityNameEn: item.clearance_stt_commodity_name_en || "-",
            })
          }),
    });
  }
  public convertUpdateBulkClearanceDataFromApi(res: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      data: {
        clearanceNo: data.data.clearence_no,
        totalSTTSuccess: data.data.total_stt_success,
        totalSTTFailed: data.data.total_stt_failed,
        listSTTFailed: data.data.list_stt_failed
      }
    })
  }
}
