import { QueryParamsEntities } from "./MainApp";
import { Pagination } from "./Pagination";

export class ClearanceEntities {
    pagination: Pagination;
    data: ClearanceData[];
  
    constructor(pagination: Pagination, data: ClearanceData[]) {
      this.pagination = pagination;
      this.data = data;
    }
  }

  export class ClearanceData {
    clearanceNo = "";
    clearanceTotalWeight = 0;
    clearanceTotalPieces = 0;
    clearanceCreatedAt = "";
    clearanceCreatedBy = "";
    clearanceCreatedName = "";
    constructor(fields?: Partial<ClearanceData>) {
      Object.assign(this, fields);
    }
  }

  export class ClearanceListRequest {
    page = 1;
    limit = 10;
    startDate = "";
    endDate = "";
    search = "";
    constructor(fields?: Partial<ClearanceListRequest>) {
      Object.assign(this, fields);
    }
  
    toQueryString(): string {
      return new QueryParamsEntities(this).queryString;
    }
  }

  export class ClearanceDetailData {
    clearanceNo = "";
    clearanceTotalWeight = 0;
    clearanceTotalPieces = 0;
    clearanceTotalGoodsPrice = 0;
    clearanceCreatedAt = "";
    clearanceCreatedBy = "";
    clearanceCreatedName = "";
    clearanceDetailStt: ClearanceDetailStt[] = [];
    constructor(fields?: Partial<ClearanceDetailData>) {
      Object.assign(this, fields);
    }
  }

  export class ClearanceDetailStt {
    clearanceBagNo = "";
    clearanceSttNo = "";
    clearanceOriginCity = "";
    clearanceDestinationCity = "";
    clearanceSttWeight = 0;
    clearanceSttGoodsPrice = 0;
    clearanceSttCommodityCode = "";
    clearanceSttCommodityName = "";
    clearanceSttCommodityNameEn = "";
    constructor(fields?: Partial<ClearanceDetailStt>) {
      Object.assign(this, fields);
    }
  }
  