import {
    dateToDateString,
    ellipsisString,
    formatPriceRP
} from "@/app/infrastructures/misc/Utils";
import { ClearanceDetailData } from "@/domain/entities/Clearance";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

let doc: any;

export const partnerTypes = (type: string) => {
  switch (type) {
    case "console":
      return "Consolidator";
    case "pos":
      return "POS";
    default:
      return "Sub-Consolidator";
  }
};

export const drawCellConditions = (cell: any) => {
  const head = cell.row?.section === "head";
  const body = cell.row?.section === "body";
  return [
    cell.column?.dataKey === "product" && head,
    cell.row?.cells[3].text[0] === "",
    cell.column?.dataKey === "product" && body,
    cell.column?.dataKey === "originCity" && head,
    cell.row?.cells[1].text[0] === "",
    cell.column?.dataKey === "originCity" && body,
    cell.column?.dataKey === "destCity" && head,
    cell.column?.dataKey === "destCity" && body
  ];
};

export const drawCellData = (cell: any) => {
  if (drawCellConditions(cell)[0]) {
    cell.row.cells[3].styles = {
      ...cell.row.cells[3].styles,
      cellPadding: {
        ...cell.row.cells[3].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[3]) {
    cell.row.cells[6].styles = {
      ...cell.row.cells[6].styles,
      cellPadding: {
        ...cell.row.cells[6].styles.cellPadding,
        left: 1
      }
    };
  }
  if (drawCellConditions(cell)[5]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[6].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[6].x + 0.7,
          cell.row.cells[6].y +
            cell.row.cells[6].contentHeight /
              (1.6333 +
                2.5 *
                  cell.row.cells[6].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[6].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[6]) {
    cell.row.cells[7].styles = {
      ...cell.row.cells[7].styles,
      cellPadding: {
        ...cell.row.cells[7].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[7]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[7].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[7].x + 0.7,
          cell.row.cells[7].y +
            cell.row.cells[7].contentHeight /
              (1.6333 +
                2.5 *
                  cell.row.cells[7].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[7].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};

const manifestPdf = (
  dataHead: ClearanceDetailData,
  data: any,
  logo: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);
  // format Date
  const formatDate = (date: Date) => {
    return `${dateToDateString(new Date(date))}`;
  };


  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("Poppins", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(`Clearance Manifest [${ellipsisString(dataHead.clearanceNo, 20)}]`, 10, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(10, 25, 192, 0.2, "F");
  doc.setFont("Poppins", "normal");
  doc.setFontSize(7);

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");

  doc.text("No. Clearance", 10, 32);
  doc.text(`: ${ellipsisString(dataHead.clearanceNo, 30)}`, 33, 32);

  doc.text("Nama Partner", 10, 39);
  doc.text(`: ${ellipsisString(dataHead.clearanceCreatedName, 70)}`, 33, 39);

  doc.text("Tanggal Update", 10, 46);
  doc.text(`: ${formatDate(new Date(dataHead.clearanceCreatedAt))}`, 33, 46);

  doc.text("Total STT", 10, 53);
  doc.text(`: ${dataHead.clearanceDetailStt.length}`, 33, 53);

  // right column

  doc.text("Total STT", 85, 32);
  doc.text(`: ${dataHead.clearanceTotalPieces}`, 125, 32);

  doc.text("Total Berat STT", 85, 39);
  doc.text(`: ${dataHead.clearanceTotalWeight} Kg`, 125, 39);

  doc.text("Total Harga Barang", 85, 46);
  doc.text(`: ${formatPriceRP(dataHead.clearanceTotalGoodsPrice, "MYR")}`, 125, 46);

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTable = [
    { header: "No", dataKey: "no" },
    { header: "No. Bag", dataKey: "bagNo" },
    { header: "No. STT", dataKey: "sttNo" },
    { header: "Berat", dataKey: "grossWeight" },
    { header: "Harga Barang", dataKey: "goodsPrice" },
    { header: "Jenis Komoditi", dataKey: "commodityType" },
    { header: "Origin", dataKey: "originCity" },
    { header: "Dest.", dataKey: "destCity" }
  ];
  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 30 },
    2: { cellWidth: 32 },
    3: { cellWidth: 17 },
    4: { cellWidth: 30 },
    5: { cellWidth: 25 },
    6: { cellWidth: 22 },
    7: { cellWidth: 24 }
  };
  doc.autoTable({
    body: data,
    columns: columnsTable,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 63 : 0,
    margin: { top: 7, left: 10, right: 10, bottom: 7 },
    headStyles: {
      font: "Poppins",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#4D4D4D",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "Poppins",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#4D4D4D",
      fontSize: 7,
      halign: "left",
      valign: "top",
      lineColor: "#E0E0E0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text("Lion Parcel Staff", 50 + 90, lastY);
  doc.text("(......................................)", 48 + 90, lastY + 20);
  doc.text("PPJK Staff", 88 + 90, lastY);
  doc.text("(......................................)", 82 + 90, lastY + 20);

  doc.setProperties({
    title: "Clearance Manifest"
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default manifestPdf;
